<template>
  <div class="login-page" align="center">

    <v-card color="darkGrey" elevation="3" class="pa-8 scrolling">

      <v-row>
        <v-col align="center">
            <span class="secondary--text text-uppercase font-weight-bold d-flex align-center justify-center" style="width: 100%; height: 180px; font-size: 1.3rem">
              Agendease
            </span>
        </v-col>
      </v-row>

      <v-form v-model="loginFormValid" ref="loginForm" v-if="showLogin">

        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Email"
              v-model="email"
              :rules="emailRules"
              solo
              hide-details="auto"
              v-on:keyup.enter="login"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Password"
              solo
              v-model="password"
              :rules="passwordRules"
              :type="show ? 'text' : 'password'"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
              hide-details="auto"
              v-on:keyup.enter="login"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="secondary--text d-flex justify-space-between">
            <v-checkbox
              color="secondary"
              label="Remember"
              class="secondary--text ma-0 remember-password"
            ></v-checkbox>
            <v-btn text color="secondary" @click="forgot = !forgot">
              Forgot?
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="secondary" depressed block height="48" @click="login" v-if="!loading">
              Sign in
            </v-btn>
            <v-progress-circular
              v-else
              class="d-flex mx-auto"
              indeterminate
              color="white"
            >
            </v-progress-circular>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
              <span class="white--text">
                Don't have an account?
              </span>
              <v-btn text color="secondary" @click="register = !register">
                Register
              </v-btn>
          </v-col>
        </v-row>

      </v-form>

      <v-form v-if="forgot" ref="forgotForm" v-model="forgotFormValid">

        <v-row>
          <v-col>
            <span class="white--text">
              Confirm your email and we will send you instructions to reset your password.
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Email"
              solo
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <template>
            <vue-recaptcha sitekey="6LeD2EEaAAAAAIThggjIDJwcivvhbeEeqMB9xZhJ" :loadRecaptchaScript="true">
            </vue-recaptcha>
          </template>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="secondary" depressed block height="48">
              Reset Password
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="transparent" depressed block height="48" @click="forgot = !forgot" class="secondary--text">
              Back to Login
            </v-btn>
          </v-col>
        </v-row>

      </v-form>

      <v-form v-if="register" ref="registerForm" v-model="registerFormValid">

        <v-row>
          <v-col>
            <span class="white--text">
              Please register your Agendease account here. Be aware that your account can belong to multiple municipalities at the same time, so we recommend you use a personal email address. Each municipality can add your professional or secondary email to their system without exposing your personal email address.
            </span>
            <br /><br />
            <span class="white--text">
              {{ this.aliasEmailMessage }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Email"
              v-model="registerInfo.email"
              :rules="emailRules"
              required
              solo
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="First Name"
              v-model="registerInfo.firstName"
              :rules="nameRules"
              required
              solo
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Last Name"
              v-model="registerInfo.lastName"
              :rules="nameRules"
              solo
              required
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Password"
              v-model="registerInfo.password"
              :rules="newPasswordRules"
              type="password"
              required
              solo
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Confirm Password"
              v-model="confirmPassword"
              :rules="confirmPasswordRules"
              type="password"
              required
              solo
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <template>
              <vue-recaptcha sitekey="6LeD2EEaAAAAAIThggjIDJwcivvhbeEeqMB9xZhJ" :loadRecaptchaScript="true" @verify="canRegister = true">
              </vue-recaptcha>
            </template>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="secondary" :disabled="this.canRegister === false" depressed block height="48" @click="registerUser()">
              <v-progress-circular v-if="processing===true" indeterminate size="16"></v-progress-circular>
              Register
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="transparent" depressed block height="48" @click="register = !register" class="secondary--text">
              Back to Login
            </v-btn>
          </v-col>
        </v-row>

      </v-form>

    </v-card>

  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import '../assets/css/style.scss'
import { mapGetters, mapState } from 'vuex'
import VueRecaptcha from 'vue-recaptcha'

export default Vue.extend({
  components: { VueRecaptcha },
  name: 'Login',
  data () {
    return {
      forgot: false,
      show: false,
      register: false,
      showLogin: true,
      loginFormValid: false,
      forgotFormValid: false,
      registerFormValid: false,
      email: '',
      password: '',
      aliasEmailMessage: '',
      canRegister: false,
      processing: false,
      registerInfo: {
        email: null,
        firstName: null,
        lastName: null,
        password: null,
        payload: null
      },
      confirmPassword: null,
      passwordRules: [
        v => !!v || 'Password is required'
      ],
      newPasswordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 8) || 'Password must be at least 8 characters in length',
        v => /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/.test(v) || 'Please use at least one special character and one uppercase letter'
      ],
      confirmPasswordRules: [
        v => v === this.registerInfo.password || 'Passwords must match'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid'
      ],
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 50) || 'Name must be less than 50 characters'
      ]
    }
  },
  watch: {
    forgot: function (value) {
      if (value === true) {
        this.showLogin = false
        this.register = false
      } else {
        this.showLogin = true
      }
    },
    register: function (value) {
      if (value === true) {
        this.showLogin = false
        this.forgot = false
      } else {
        this.showLogin = true
      }
    }
  },
  computed: {
    ...mapGetters('auth', [
      'isLogged'
    ]),
    ...mapState('loader', ['loading'])
  },
  mounted () {
    if (this.isLogged) {
      this.$router.push({ name: 'Boards' })
    }
    if (this.$route.query.payload) {
      this.registerInfo.payload = this.$route.query.payload
    }
    if (this.$route.query.new === 'true') {
      this.showLogin = false
      this.register = true
      if (this.$route.query.payload) {
        axios({
          method: 'get',
          url: process.env.VUE_APP_BACKEND_URL + '/api/get-meta/temp/' + this.$route.query.payload
        }).then((response) => {
          this.registerInfo.firstName = response.data.first_name
          this.registerInfo.lastName = response.data.last_name
          this.aliasEmailMessage = response.data.email + ' will be associated with the account you create here, but your personal email will not be exposed to others.'
        })
      }
    }
  },
  methods: {
    registerUser () {
      this.$refs.registerForm.validate()

      if (!this.registerFormValid) {
        return
      }
      this.processing = true
      this.$store
        .dispatch('auth/register', {
          email: this.registerInfo.email,
          firstName: this.registerInfo.firstName,
          lastName: this.registerInfo.lastName,
          payload: this.registerInfo.payload,
          password: this.registerInfo.password
        }).then((response) => {
          if (response.data.status === 'success') {
            this.$store
              .dispatch('client/clientList')
              .then(() => {
                this.processing = false
                this.$router.push({ name: 'Boards' })
              })
          }
        })
    },
    login () {
      this.$refs.loginForm.validate()

      if (!this.loginFormValid) {
        return
      }

      this.$store
        .dispatch('auth/login', {
          email: this.email,
          password: this.password,
          payload: this.registerInfo.payload
        })
        .then(() => {
          this.$store
            .dispatch('client/clientList')
            .then(() => {
              this.$router.push({ name: 'Boards' })
            })
        })
    }
  }
})
</script>

<style scoped>
  .scrolling {
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;
    max-height: 100%;
  }
</style>
